import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SEO from 'components/Seo'
import AppHeading from 'components/AppHeading'
import { useAuth } from 'store/Auth'
import TourList from 'components/widgets/Tour/List'
import { isVip as checkVip } from 'utils'
import { pageView } from 'utils/tracker'
import { TRACKING } from 'config/constants/common'
import './index.scss'

export default function Profile(props) {
  const { authData } = useAuth()
  const { t } = useTranslation(['welcome'])
  const isVIP = checkVip(authData)
  const { displayName = '' } = authData || {}
  const { location } = props || {}
  const { state: locationState } = location || {}
  const { tabIndex: defaultTabIndex } = locationState || {}
  const [, setTabActiveIndex] = useState(defaultTabIndex || 0)
  const [isFirstView, setFirstView] = useState(true)

  useEffect(() => {
    if (isVIP) {
      if (isFirstView) {
        pageView(trackingEventSource)
        setFirstView(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => {
    setTabActiveIndex(defaultTabIndex || 0)
  }, [defaultTabIndex])

  const trackingEventSource = TRACKING.SOURCE.VIP_WELCOME
  const appTitle = `${t('lbl_welcome_en')}, ${displayName}`

  return (
    <div className={'vipwelcome-view Layout-children'}>
      <SEO title={appTitle} />
      <AppHeading title={appTitle} />

      <div className="content">
        <div className="main" style={{ paddingBottom: '50px', paddingTop: '10px' }}>
          <TourList fromVIP />
        </div>
      </div>
    </div>
  )
}
